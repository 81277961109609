//Homepage News Slider
$('#news-carousel').owlCarousel({
	loop: true,
	margin: 30,
	nav: false,
	responsive: {
		0: {
			items: 1,
			slideBy: 1
		},
		1000: {
			items: 2,
			slideBy: 2
		}
	}
});

//Company Profile Map
/*function initMap() {
	var lat = parseFloat(document.getElementById('map').dataset.lat);
	var lng = parseFloat(document.getElementById('map').dataset.lng);
	
	var uluru = {lat: lat, lng: lng};

	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 10,
		center: uluru
	});
	
	var marker = new google.maps.Marker({
		position: uluru,
		map: map
	});
}*/

function initMap() {
	var map = new google.maps.Map(document.getElementById('map'), {
		zoom: 12,
		center: {lat: 0, lng: 0}
	});
	
	var geocoder = new google.maps.Geocoder();
	geocodeAddress(geocoder, map);
}

function geocodeAddress(geocoder, resultsMap) {
	var address = document.getElementById('map').dataset.address;
	geocoder.geocode({'address': address}, function(results, status) {
		if (status === 'OK') {
			resultsMap.setCenter(results[0].geometry.location);
			var marker = new google.maps.Marker({
				map: resultsMap,
				position: results[0].geometry.location
			});
		} else {
			alert('Geocode was not successful for the following reason: ' + status);
		}
	});
}

//Training Boxes Duplicate HTML and switch all dark icons and text for light versions
$(function() {
	$('.training-box, .lesson-box').each( function() {
		var str = $(this)[0].innerHTML;
		
		var i = 0, strLength = str.length;
		for(i; i < strLength; i++) {
			str = str.replace("dark", "light");
		}
		
		var html = "<div class='border-top'><div class='py-5' style='margin-top: 5px;'>" + str + '</div></div>';
		
		$(this).prepend(html);
	});
});

//Temp Fix for Bootstrap 4
$('.nav-tabs').on('shown.bs.tab', 'a', function (e) {
    if (e.relatedTarget) {
        $(e.relatedTarget).removeClass('active');
    }
});

$('.training-box-wrapper, .lesson-box-wrapper').click(function() {
	$('.training-box-wrapper, .lesson-box-wrapper').removeClass('active');
	$(this).addClass('active');
});

//Fix for parallax.js and jquery 3.1.0
$( function () { $('[data-parallax="scroll"]').parallax(); });

//Initialize Multiselect
$(document).ready(function() {
	$('.multiselect').multiselect({
		numberDisplayed: 5,
		buttonClass: 'btn btn-block no-caret',
		buttonContainer: '<div class="btn-group full" />',
    	templates: {
      		li: '<li><a tabindex="0" class="dropdown-item"><label></label></a></li>',
    	}
	});
});

//rather then try to style the google image search, we use the old one and just update the values in the hidden google one
$(function() {	
	$('#submitSiteSearch').on('click', function(e) {
		e.preventDefault();
		$('#googleSearch input[type="text"]').val($('#siteSearch').val());
		$(document).find('.gsc-search-button input').click();
	});
});
